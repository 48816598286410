/* @font-face {
  font-family: 'QIJIC';
  src: url(../font/黄令东齐伋复刻（Combo）.ttf);
} */

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}


#main {
  width: 100%;
  height: 100%;
}

Canvas {
  /* height: 100% !important; */
  position: fixed;
  z-index: 1000;
}


body {
  background: #FFEBD9;
}


.number {
  width: 109px;
  height: 64px;
  right: 10%;
  position: absolute;
  z-index: 10;
  top: 50%;
}

.no {
  width: 109px;
  height: 64px;
  right: 3%;
  position: absolute;
  z-index: 10;
  top: 3%;
  color: #453325;
  font-size: 16px;
  font-weight: bold;  
}

.introduceName {
  display: flex;
  align-items: center;
  color: #453325;
  font-size: 20px;
  font-weight: bold;
}

.introduceName img {
  margin-left: 10px;
  width: 18px;
  height: 18px;
}

.introduce>span {
  color: #99775C;
  font-size: 12px;
}

.introduce {
  margin: 10px 40px;
}

.content {
  margin: 0 40px;
  line-height: 30px;
  font-size: 14px;
  color: #453325;
}

.foot {
  position: absolute;
  /* margin-top: 22%; */
  top: 62%;
}

.number img {
  width: 110px;
  height: 54px;
}

.btn {
  position: absolute;
  top: 90%;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
  z-index: 100000;
  background-image: url(../public/image/button.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  border-radius: 20px;
  color: #fff;
}

.history-item {
  width: 66px;
  height: 69px;
  margin: 10px;
}

.history-item-bg {
  position: absolute;
  opacity: 0.5;
  width: 66px;
}

.history-item-logo {
  position: absolute;  
  opacity: 1;
  width: 66px;
}

.history {
  position: absolute;
  top: 89%;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.btns {
  background-image: url(../public/image/buttons.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  margin: 0 auto;
  border-radius: 20px;
  color: #fff;
}

.btn button {
  width: 250px;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  /* font-family: 'QIJIC'; */
  /* font-family: 'Courier New', Courier, monospace; */
}

.btns button {
  width: 250px;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  /* font-family: 'QIJIC'; */
  /* font-family: 'Courier New', Courier, monospace; */
  
}

.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.css-1t20nyk-MuiSnackbar-root {
  margin-bottom: 120px;
}

.css-1exqwzz-MuiSnackbarContent-message {
  padding: 8px 90px !important;
}

.background img {
  width: 100vw;
  height: 100vh;
}

.background {
  position: absolute;
  z-index: -100;
  bottom: 0;
  top: 0px;
}
.name img{
  width: 150px;
  height: 260px;
}
.name {
  writing-mode: tb-rl;
  color: rgba(111, 81, 58, 0.9);
  font-weight: 400;
  position: absolute;
  font-size: 18px;
  top: 2%;
  right: 30%;
  /* background-image: url(../public/image/name.png); */
  background-size: 150px 250px;
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.name span {
  position: absolute;
  width: 130px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -20px;
}

#qrcode {
  width: 100%;
}